<template>
    <div v-if="center">
<mapir  :zoom="11" class="map" ref="map" :apiKey="$store.state.mapApi"
       :center="center">
  <mapAttributionControl/>
  <mapNavigationControl position="top-right"/>
  <mapGeolocateControl position="top-right"/>
    <mapMarker v-for="(marker,index) in markers" :key="index"
            :coordinates="[marker.long,marker.lat]"
            color="none"
    >
<span slot="marker">
    <v-img v-if="marker.image" class="marker" :src="$store.state.smallthumbnail+marker.image"></v-img>
  <v-img alt="لوگوی ایران آی سیب" title="ایران آی سیب" v-else class="marker" src="../assets/image/Header/logo.png"></v-img>

</span>

<map-popup>
<span style="font-family: tahoma">
    <router-link :to="'/company/'+marker.id">
    {{marker.title}}
    </router-link>
</span>
</map-popup>

    </mapMarker>

</mapir>
    </div>

</template>

<script>
    import axios from 'axios';
    import {mapir, mapMarker, mapPopup,mapNavigationControl,mapGeolocateControl,mapAttributionControl} from "mapir-vue";
    export default {


        components:{mapir,
            mapMarker,
            mapPopup,
          mapNavigationControl,
          mapGeolocateControl,
          mapAttributionControl
        },
      metaInfo() {
        return {
          title:'نقشه',
          link: [{rel: 'canonical', href: this.$store.state.url+'/map'}]
        }
      },
        data() {
            return {
                cityId: this.$store.state.cityId,
                dialog:false,
                markers:[],
            }
        },

        computed:{
            center(){return [this.$store.state.cityLong,this.$store.state.cityLat]}
        },
        created() {
            axios.get(this.$store.state.api + 'map', {params:{cityId: 117}}).then(response => {
                this.markers = response.data.data;
            });
        }
    }
</script>

<style scoped>
    .map {
/*
        border-radius: 20px;
*/
        overflow: hidden;
        /*border: 10px #fff solid;
        box-shadow: 0px 0px 10px #eee;*/
        width: 100%;
        min-height: 600px;
    }

    .marker{
        border: 2px solid #fff ;
        box-shadow: 0px 3px 3px #999;
        width: 40px;
        cursor: pointer;
        border-radius: 100%;
        background-color: #fff;
    }
</style>